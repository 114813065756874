
import {defineComponent, onMounted} from "vue";
import Loading                      from '@/components/Loading.vue';
import {useBannerSiteList}          from "../../logic/banner-site/banner-site-list";

export default defineComponent({
  name      : "BannerSiteList",
  components: {
    Loading: Loading,
  },
  setup() {
    const {sites, sitesReady, loadSites, deleteSite} = useBannerSiteList();

    onMounted(() => loadSites());

    return {sites, sitesReady, deleteSite}
  },
})
