<template>
  <h2>Сайты</h2>
  <Loading v-if="!sitesReady"></Loading>
  <router-link :to="{name: 'sites-form'}" class="uk-button uk-button-primary">Добавить размер</router-link>
  <table class="uk-table uk-table-divider" v-if="sitesReady">
    <caption>Учетные записи</caption>
    <thead>
    <tr>
      <th>ID</th>
      <th>Название</th>
      <th>Ссылка</th>
      <th></th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="site in sites">
      <td>{{ site.id }}</td>
      <td>{{ site.name }}</td>
      <td><a class="uk-link-text" :href="site.domain" target="_blank">{{site.domain}}</a></td>
      <td>
        <button class="uk-button uk-button-text" @click="deleteSite(site.id)">&times;</button>
      </td>
    </tr>
    </tbody>
  </table>
</template>

<script lang="ts">
import {defineComponent, onMounted} from "vue";
import Loading                      from '@/components/Loading.vue';
import {useBannerSiteList}          from "../../logic/banner-site/banner-site-list";

export default defineComponent({
  name      : "BannerSiteList",
  components: {
    Loading: Loading,
  },
  setup() {
    const {sites, sitesReady, loadSites, deleteSite} = useBannerSiteList();

    onMounted(() => loadSites());

    return {sites, sitesReady, deleteSite}
  },
})
</script>
