import {ref}            from 'vue';
import ApiListFetcher   from "../../services/api/ApiListFetcher";
import {Api}            from "../../types/api/v1/electrification";
import LaravelIndexResponse = Api.V1.LaravelIndexResponse;
import BannerSiteUrlLoc from "../../services/api/banner-site/BannerSiteUrlLoc";
import BannerSite = Api.V1.BannerSite;

export const useBannerSiteList = () => {
    const sites      = ref<Array<BannerSite>>([]);
    const sitesReady = ref<boolean>(true);

    const fetcher = new ApiListFetcher<BannerSite>(new BannerSiteUrlLoc());

    // @ts-ignore
    const setSites = (value: Array<BannerSite>) => sites.value = value;

    const loadSites = () => {
        fetcher.index().then((data: LaravelIndexResponse<BannerSite>) => {
            setSites(data.data);
            sitesReady.value = true;
        });
    }

    const deleteSite = (id: number) => {
        fetcher.delete(id).then(() => {
            loadSites();
        })
    }

    return {sites, sitesReady, setSites, loadSites, deleteSite};
};